<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15 row">
            <div class="col-7">
                <div class="pd-t-5 pd-b-5">
                    <h1 class="pd-0 mg-0 tx-20 text-overflow">Laporan Mutasi</h1>
                </div>
                <div class="breadcrumb pd-0 mg-0">
                    <a class="breadcrumb-item" href="#"><i class="fa fa-check-square-o mg-r-5"></i>Laporan</a>
                    <span class="breadcrumb-item active"> Mutasi</span>
                </div>
            </div>
            <div v-if="userdata.role == 'auditor' || userdata.role == 'administrator'" class="col-5">
                Filter SKPD:
                <v-select 
                        :options="listPengguna"
                        :reduce="(label) => label.code"
                        label="label"
                        v-model="filter"
                        @input="handleFilter"
                        ></v-select>
            </div>
        </div> 

        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari Spesifikasi Barang...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                    <v-select 
                                        :options="listBidang"
                                        :reduce="(label) => label.code"
                                        :style="{ width: '300px'}"
                                        :class="'ml-3'"
                                        label="label"
                                        v-model="filterBidang"
                                        ></v-select>
                                    <select v-model="filterMode" v-on:change="filterTipe()" class=" ml-3" :style="'width:100px'">
                                        <option value="custom">Custom</option>
                                        <option value="semester1">Semester 1</option>
                                        <option value="semester2">Semester 2</option>
                                    </select>
                                    <!-- <input v-if="filterMode != 'custom'" type="date" disabled class="form-control border-danger" style="width:30px" required=""
                                                v-model="form.start_date" placeholder="Tanggal Awal">
                                    <input v-if="filterMode != 'custom'" type="date" disabled class="form-control border-danger" style="width:30px" required=""
                                                v-model="form.end_date" placeholder="Tanggal Akhir">

                                    <input v-if="filterMode == 'custom'" type="date" class="form-control" style="width:30px !important" required=""
                                                v-model="form.start_date" placeholder="Tanggal Awal">
                                    <input v-if="filterMode == 'custom'" type="date" class="form-control" style="width:30px !important" required=""
                                                v-model="form.end_date" placeholder="Tanggal Akhir"> -->
                                    
                                    <datepicker v-if="filterMode != 'custom'" disabled :input-class="'form-control border-danger'" :style="'width:130px'" required=""
                                                v-model="form.start_date" placeholder="Tanggal Awal"></datepicker>
                                    <datepicker v-if="filterMode != 'custom'" disabled :input-class="'form-control border-danger'" :style="'width:130px'" required="" :disabledDates="disabledDates"
                                                v-model="form.end_date" placeholder="Tanggal Akhir"></datepicker>

                                    <datepicker v-if="filterMode == 'custom'" :input-class="'form-control border-dark'" :style="'width:130px'" required="" :disabledDates="disabledStart"
                                                v-model="form.start_date" placeholder="Tanggal Awal"></datepicker>
                                    <datepicker v-if="filterMode == 'custom'" :input-class="'form-control border-dark'" :style="'width:130px'" required="" :disabledDates="disabledDates"
                                                v-model="form.end_date" placeholder="Tanggal Akhir"></datepicker>

                                    <button type="button" v-on:click="getData()" class=" btn btn-secondary"><i class="fa fa-filter"></i></button>
                                    <!-- DOMAIN UTAMA -->
                                    <a v-if="userdata.role != 'auditor' && userdata.role != 'administrator' && checkDomain != 'persediaan.e-planningbmd.id'" target="_blank" :href="'https://persediaanbmd.bandungkab.go.id/print/Home/laporan_mutasi/' + tokenFix + '/' + userdata.id + '/' + '/' + form.start_date + '/' + form.end_date + '/' + filterBidang" class="ml-2 btn btn-success text-white"><i class="fa fa-file mg-r-5"></i> Unduh Laporan</a>
                                    <a v-if="(userdata.role == 'auditor' || userdata.role == 'administrator') && checkDomain != 'persediaan.e-planningbmd.id'" target="_blank" :href="'https://persediaanbmd.bandungkab.go.id/print/Home/laporan_mutasi/' + tokenFix + '/' + filter + '/' + '/' + form.start_date + '/' + form.end_date + '/' + filterBidang" class="ml-2 btn btn-success text-white"><i class="fa fa-file mg-r-5"></i> Unduh Laporan</a>
                                    <!-- DOMAIN CADANGAN -->
                                    <a v-if="userdata.role != 'auditor' && userdata.role != 'administrator' && checkDomain == 'persediaan.e-planningbmd.id'" target="_blank" :href="'https://print-persediaan.e-planningbmd.id/Home/laporan_mutasi/' + tokenFix + '/' + userdata.id + '/' + '/' + form.start_date + '/' + form.end_date + '/' + filterBidang" class="ml-2 btn btn-success text-white"><i class="fa fa-file mg-r-5"></i> Unduh Laporan</a>
                                    <a v-if="(userdata.role == 'auditor' || userdata.role == 'administrator') && checkDomain == 'persediaan.e-planningbmd.id'" target="_blank" :href="'https://print-persediaan.e-planningbmd.id/Home/laporan_mutasi/' + tokenFix + '/' + filter + '/' + '/' + form.start_date + '/' + form.end_date + '/' + filterBidang" class="ml-2 btn btn-success text-white"><i class="fa fa-file mg-r-5"></i> Unduh Laporan</a>
                                    
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table border=1 class="m-2 table table-sm table-striped  table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th rowspan="2" class="text-center" style="width:4%">No</th>
                                            <th rowspan="2" class="text-center">Kode Barang</th>
                                            <th rowspan="2" class="text-center">Spesifikasi Barang</th>
                                            <th rowspan="2" class="text-center">Saldo Awal</th>
                                            <th colspan="2" class="text-center">Transaksi Masuk</th>
                                            <th colspan="4" class="text-center">Transaksi Keluar</th>
                                            <th v-if="cekFilterBidang == ''" rowspan="2" class="text-center">Saldo Akhir</th>
                                        </tr>
                                        <tr>
                                            <th>Pembelian</th>
                                            <!-- <th>Transfer Masuk</th> -->
                                            <th>Hibah Masuk</th>
                                            <th>Pemakaian</th>
                                            <!-- <th>Transfer Keluar</th> -->
                                            <th>Hibah Keluar</th>
                                            <th>Usang</th>
                                            <th>Rusak</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(item, index) in listData" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>{{ item.code }}</td>
                                            <td>{{ item.name }}</td>
                                            <td class="text-center">{{ item.saldo }}</td>
                                            <td class="text-center">{{ item.pembelian }}</td>
                                            <!-- <td class="text-center">{{ item.transfer_masuk }}</td> -->
                                            <td class="text-center">{{ item.hibah_masuk }}</td>
                                            <td class="text-center">{{ item.pemakaian }}</td>
                                            <!-- <td class="text-center">{{ item.transfer_keluar }}</td> -->
                                            <td class="text-center">{{ item.hibah_keluar }}</td>
                                            <td class="text-center">{{ item.usang }}</td>
                                            <td class="text-center">{{ item.rusak }}</td>
                                            <td v-if="cekFilterBidang == ''" class="text-center">{{ item.akhir }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="11" class="text-center">
                                                <div class="sk-wave">
                                                    <div class="sk-rect sk-rect1 mr-1"></div>
                                                    <div class="sk-rect sk-rect2 mr-1"></div>
                                                    <div class="sk-rect sk-rect3 mr-1"></div>
                                                    <div class="sk-rect sk-rect4 mr-1"></div>
                                                    <div class="sk-rect sk-rect5"></div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching && listData.length == 0">
                                        <tr>
                                            <td colspan="11" class="text-center">
                                                - Data Tidak Tersedia -
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ADD SPACE -->
                <br><br><br>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import vPagination from 'vue-plain-pagination'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';

    import Vue from 'vue'
    import VueMask from 'v-mask'
    Vue.use(VueMask);


    import createNumberMask from 'text-mask-addons/dist/createNumberMask';
    const currencyMask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        decimalLimit: 7,
        includeThousandsSeparator: true,
        allowNegative: false,
    });
    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Data Persediaan'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                tokenFix: localStorage.auth_token,
                currencyMask,
                listData: [],
                fetching: true,
                listPengguna: [],
                listBidang: [],
                filter: '',
                filterBidang: '',
                cekFilterBidang: '',
                filterMode: 'custom',
                checkDomain: '',
                form: {
                    start_date: new Date('2024-01-01'),
                    end_date: new Date('2024-12-31'),
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.$store.dispatch('loadUserData');
            this.getData();
            this.getUser();
            this.getBidang();
            this.check_domain();
        },
        computed: {
            ...mapState(['userdata']),
            disabledDates() {
                const startDate = this.userdata.email + '-12-31';
                return {
                    from: new Date(startDate),
                    to: new Date(this.form.start_date),
                };
            },
            disabledStart() {
                const startDate = this.userdata.email + '-12-31';
                const endDate   = this.userdata.email + '-01-01';

                return {
                    from: new Date(startDate),
                    to: new Date(endDate)
                };
            },
        },
        components: {
            vPagination,
            Datepicker,
            'v-select' : vSelect,
        },
        methods: {
            check_domain() {
                this.checkDomain = window.location.hostname;
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            },
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'laporan/mutasi', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        start: new Date(this.form.start_date),
                        end: new Date(this.form.end_date),
                        filter: this.filter,
                        filterBidang: this.filterBidang
                    }
                })
                    .then((response) => {
                        if(this.filterBidang != ''){
                            this.cekFilterBidang = 1;
                        }else{
                            this.cekFilterBidang = '';
                        }
                        this.listData = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            getUser() {
                this.fetching = true;
                if(this.userdata.role == 'auditor' || this.userdata.role == 'administrator'){
                    this.$http.get(this.baseUrl + 'admin/master/pengguna/list', {
                    params: {
                            keywords: this.keywords,
                        }
                    })
                        .then((response) => {
                            let data = response.data.data;
                            data.forEach((item) => {
                                this.listPengguna.push({
                                label: item.name,
                                code: item.id,
                                });
                            });
                        })
                        .catch((error) => {
                            console.log(error.response.data);
                        }); 
                }
            },
            getBidang() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/bidang', {
                params: {
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        let data = response.data.data;
                        this.listBidang = [{
                            label: '- Semua Bidang -',
                            code: '', 
                        }];
                        data.forEach((item) => {
                            this.listBidang.push({
                            label: item.bidang,
                            code: item.id,
                            });
                        });
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    }); 
            },
            handleFilter: function() {
                this.getData();
            },
            filterTipe(){
                if(this.filterMode == 'semester1'){
                    this.form.start_date = new Date('2024-01-01')
                    this.form.end_date = new Date('2024-06-30')
                }else if(this.filterMode == 'semester2'){
                    this.form.start_date = new Date('2024-07-01')
                    this.form.end_date = new Date('2024-12-31')
                }else{
                    this.form.start_date = new Date('2024-01-01')
                    this.form.end_date = new Date('2024-12-31')
                }
            }
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>